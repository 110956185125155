import { ClientError } from '@wildidea/tools/errors/ClientError.js'
import { UnexpectedError } from '@wildidea/tools/errors/ApiError/UnexpectedError.js'
import { ApiError } from '@wildidea/tools/errors/ApiError/ApiError.js'
import { NotFoundError } from '@wildidea/tools/errors/ApiError/NotFoundError.js'

import {
  API_PREFIX,
  GetUserTracksInput,
  GetUserTracksResponse,
  TrackingProviderId
} from './model.js'

const OPERATION = 'getUserTracks'

export type GetUserTracksThrows = ClientError | NotFoundError | UnexpectedError

/** @throws GetUserTracksThrows */
export const getUserTracks = async (input: GetUserTracksInput): Promise<GetUserTracksResponse> => {
  const searchParams = new URLSearchParams(
    Object.entries({
      startDate: input.timeRange?.startDate?.toISOString(),
      endDate: input.timeRange?.endDate?.toISOString(),
      trackIds: input.trackIds?.join(',')
    }).filter<[string, string]>((entry): entry is [string, string] => !!entry[1])
  )

  return handleGetUserTracksResponse(
    window.fetch(
      `${API_PREFIX}/users/${new TrackingProviderId(input.user).toString()}/tracks${searchParams.size ? `?${searchParams}` : ''}`,
      {
        headers: {
          Accept: 'application/json'
        }
      }
    )
  )
}

const handleGetUserTracksResponse = async (
  promise: Promise<Response>
): Promise<GetUserTracksResponse> => {
  let response: Response
  try {
    response = await promise
  } catch (error) {
    throw new ClientError({ operation: OPERATION, cause: error })
  }

  if (response.ok) {
    return (await response.json()) as GetUserTracksResponse
  }

  throw ApiError.fromJSON({
    operation: OPERATION,
    json: await response.json()
  })
}
