// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HOJn4__overlay {
  pointer-events: all;
  padding: 5em max(0.5em, min(3vw, 2em)) 0;
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
}
.HOJn4__overlay .ZSB79__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
.HOJn4__overlay .diDR3__content {
    position: relative;
    height: 100%;
    pointer-events: none;
  }
.HOJn4__overlay .diDR3__content > * {
      pointer-events: all;
    }
`, "",{"version":3,"sources":["webpack://./src/app/routes/OverlayViewport.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wCAAwC;EACxC,YAAY;EACZ,cAAc;EACd,yBAAyB;AAmB3B;AAjBE;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;EACT;AAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;EAKtB;AAHE;MACE,mBAAmB;IACrB","sourcesContent":[".overlay {\n  pointer-events: all;\n  padding: 5em max(0.5em, min(3vw, 2em)) 0;\n  height: 100%;\n  overflow: auto;\n  overscroll-behavior: none;\n\n  & .backdrop {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  }\n\n  & .content {\n    position: relative;\n    height: 100%;\n    pointer-events: none;\n\n    & > * {\n      pointer-events: all;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var overlay = `HOJn4__overlay`;
export var backdrop = `ZSB79__backdrop`;
export var content = `diDR3__content`;
export default ___CSS_LOADER_EXPORT___;
