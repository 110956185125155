import { getTilesetEndpoint } from './getTilesetEndpoint.js'
import { getUserMessages } from './getUserMessages.js'
import { getUserStatus } from './getUserStatus.js'
import { getUserTracks } from './getUserTracks.js'
import { IUserDataService } from '../src/domain/IUserDataService.js'

export class VectorTileServiceClient {
  constructor() {}
  getTilesetEndpoint = getTilesetEndpoint
}

export class UserDataServiceClient implements IUserDataService {
  constructor() {}

  getStatus = getUserStatus
  getMessages = getUserMessages
  getTracks = getUserTracks
}

export * from './model.js'
