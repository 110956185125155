import React from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useTransition } from '@react-spring/web';

import { useThemeAnimations } from '@wildidea/components/Theme/Theme';
import { useAnimatedRouteState } from '@wildidea/components/AnimatedRoutes/AnimatedRoutes';

import * as css from './OverlayViewport.module.css';

const OverlayViewport: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { active, onTransitionEnd } = useAnimatedRouteState();
  const { inPlace } = useThemeAnimations('Common');
  const backdropTransition = useTransition(active, {
    ...inPlace,
    onRest: onTransitionEnd
  });
  return (
    <div className={css.overlay}>
      {backdropTransition((style, active) =>
        active ? (
          <div
            className={css.backdrop}
            style={style}
            onClick={() => {
              navigate('/');
            }}
          />
        ) : null
      )}
      <div className={css.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default OverlayViewport;
