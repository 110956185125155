// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  html and body styles are in the HTML document so they are available as soon as the document is
  received
*/

.header {
  position: relative;
  display: flex;
  align-items: center;
}

.header h1 {
    margin: 0;
    flex-shrink: 0;
  }

main {
  max-width: max(90%, calc(100% - 2em));
  margin: 0 auto;
  margin-bottom: 7em;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sandbox {
  background: var(--color-grey-0);
  margin: 1em;
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./src/app/viewport.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAED;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AAMrB;;AAJE;IACE,SAAS;IACT,cAAc;EAChB;;AAGF;EACE,qCAAqC;EACrC,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,+BAA+B;EAC/B,WAAW;EACX,iBAAiB;AACnB","sourcesContent":["/*\n  html and body styles are in the HTML document so they are available as soon as the document is\n  received\n*/\n\n.header {\n  position: relative;\n  display: flex;\n  align-items: center;\n\n  & h1 {\n    margin: 0;\n    flex-shrink: 0;\n  }\n}\n\nmain {\n  max-width: max(90%, calc(100% - 2em));\n  margin: 0 auto;\n  margin-bottom: 7em;\n}\n\n@keyframes fade {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.sandbox {\n  background: var(--color-grey-0);\n  margin: 1em;\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
