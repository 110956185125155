import { ClientError } from '@wildidea/tools/errors/ClientError.js'
import { UnexpectedError } from '@wildidea/tools/errors/ApiError/UnexpectedError.js'
import { ApiError } from '@wildidea/tools/errors/ApiError/ApiError.js'
import { NotFoundError } from '@wildidea/tools/errors/ApiError/NotFoundError.js'

import { API_PREFIX, ITrackingProviderId, TrackingProviderId, UserStatus } from './model.js'

const OPERATION = 'getUserStatus'

export type GetUserStatusThrows = ClientError | NotFoundError | UnexpectedError

/** @throws GetUserStatusThrows */
export const getUserStatus = async (input: ITrackingProviderId): Promise<UserStatus> => {
  return handleGetUserStatusResponse(
    window.fetch(`${API_PREFIX}/users/${new TrackingProviderId(input).toString()}/status`, {
      headers: {
        Accept: 'application/json'
      }
    })
  )
}

const handleGetUserStatusResponse = async (promise: Promise<Response>): Promise<UserStatus> => {
  let response: Response
  try {
    response = await promise
  } catch (error) {
    throw new ClientError({ operation: OPERATION, cause: error })
  }

  if (response.ok) {
    return (await response.json()) as UserStatus
  }

  throw ApiError.fromJSON({
    operation: OPERATION,
    json: await response.json()
  })
}
