export enum TrackingProvider {
  InReach = 'inreach'
}

export const isTrackingProvider = (str: string): str is TrackingProvider =>
  Object.values(TrackingProvider).includes(str as TrackingProvider)

/**
 * Represents a unique identifier within a third-party service.
 *
 * We aggregate data across multiple services, so we need to avoid collision between unique
 * identifiers within the two systems. This is a Compound ID that achieves that.
 */
export interface ITrackingProviderId {
  provider: TrackingProvider
  id: string
  toString: unknown
}

const tsid: unique symbol = Symbol('tsid')
/**
 * Represents a unique identifier within a third-party tracking provider's services.
 *
 * We aggregate data across multiple services, so we need to avoid collision between unique
 * identifiers within various systems. This is a Compound ID that achieves that.
 *
 * This class includes methods to aid in serialization.
 */
export class TrackingProviderId implements ITrackingProviderId {
  provider: TrackingProvider
  id: string;
  [tsid] = tsid
  constructor(providerId: ITrackingProviderId) {
    this.provider = providerId.provider
    this.id = providerId.id.toLowerCase()
  }
  static fromString(idStr: string) {
    const [provider, id] = idStr.split('-')
    if (!isTrackingProvider(provider)) {
      throw new Error(
        `The given provider prefix "${provider}" is not a recognized TrackingProvider.`
      )
    }
    return new TrackingProviderId({ provider: provider, id })
  }
  toString() {
    return `${this.provider}-${this.id}`
  }
  equals(id: ITrackingProviderId) {
    return id.id === this.id && id.provider === this.provider
  }
}
