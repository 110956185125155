import type { LngLat } from '@wildidea/tools/utils/geospatial.js'

import { ITrackingProviderId } from './TrackingProvider.js'
import { TrackingDevice } from './TrackingDevice.js'
import { TrackingUser } from './TrackingUser.js'

export interface GeoEvent {
  id: ITrackingProviderId
  /**
   * Description of the event. See Event Log Types:
   * https://support.garmin.com/en-US/?faq=tdlDCyo1fJ5UxjUbA9rMY8
   */
  type: GeoEventType
  user: TrackingUser | null
  device: TrackingDevice | null
  /** Geolocation of the event */
  geo: Geolocation
  /** Earliest known timing, ISO Date string */
  timestamp: string
  /** Message content */
  message: string | null
  track: GeoEventTrackData | null
}

export interface AnonymousGeoEvent extends GeoEvent {
  user: null
  device: null
  message: null
}

export interface DetailedGeoEvent extends GeoEvent {
  user: TrackingUser
  device: TrackingDevice
}

export interface GeoMessage extends DetailedGeoEvent {
  type: GeoEventType.Message
  message: string
}

export interface Geolocation {
  location: LngLat
  /** meters */
  elevation: number | null
  /** meters/second */
  speed: number | null
  /** degrees */
  heading: number | null
  /** Whether the point represents an accurate GPS fix */
  isGPSFix: boolean
}

export interface GeoEventTrackData {
  /** The owning track's ID. */
  id: string
  /** The computed distance along the track so far for this point. */
  distanceAlong: number
  /** The previous point in the track, if any */
  previousEventId: ITrackingProviderId | null
  /** The next point in the track, if any */
  nextEventId: ITrackingProviderId | null
  /** This event's absolute sequence position within the overall Track */
  index: number
}

/** See https://support.garmin.com/en-US/?faq=tdlDCyo1fJ5UxjUbA9rMY8 */
export enum GeoEventType {
  /**
   * Device has sent text.
   * Multiple service-specific messaging events are coalesced into this event.
   */
  Message = 'Message',
  /** Device has sent a track point */
  TrackingPoint = 'Tracking:Point',
  /** Device started tracking */
  TrackingStart = 'Tracking:Start',
  /** Device has stopped tracking */
  TrackingStop = 'Tracking:Stop',
  /** Device has changed how often it sends track points */
  TrackingIntervalChanged = 'Tracking:IntervalChanged',
  /** Device has responded to a Location Request */
  LocationResponse = 'LocationRequest:Response',
  /** Devices replied to a Test */
  TestResponse = 'DeviceTestRequest:Response',
  /** Device has started following a route */
  NavigationStart = 'Navigation:Start',
  /** Device has stopped following a route */
  NavigationStop = 'Navigation:Stop',
  /** Device is sending a new/changed waypoint or route definition */
  NavigationWaypointChanged = 'Navigation:WaypointChanged',
  /** Device has sent a Reference Point */
  ReferencePoint = 'ReferencePoint',
  /** Device is declaring an SOS */
  SOSStart = 'SOS:Start',
  /** Device canceling SOS */
  SOSStop = 'SOS:Stop',
  /** Device has received acknowledgment from the emergency response team and is continuing with SOS */
  SOSConfirm = 'SOS:Confirm'
}

export const EVENT_TYPE_GEOEVENTS_RECEIVED = 'GeoEvents:Received'
export interface GeoEventsReceivedEventDetail {
  events: GeoEvent[]
}

export interface GeoEventFeatureProperties extends Pick<GeoEvent, 'type'> {
  /** Generated with `TrackingServiceId.toString()`. */
  id: string
  /**
   * Generated with `TrackingServiceId.toString()`.
   *
   * `null` when the event is anonymous, such as when it is part of an Explore tile.
   */
  userId: string | null
  /**
   * If this `GeoEvent` belongs to a track, this is its `id`.
   *
   * Otherwise, this is `null`.
   */
  trackId: string | null
  /**
   * If this `GeoEvent` belongs to a track, this is the owning track's `color`.
   *
   * Otherwise, this is `null`.
   */
  color: number | null
  /**
   * Used to generate a date label for important track events.
   *
   * For `GeoEvent.TrackingStart` and `GeoEvent.TrackingStop`, this is defined. When present, it is
   * the numeric month of the year that the event took place, starting with 1 (for the date Dec 1
   * 2024, this value would be `"12"`).
   *
   * For all other event types, it is `null`.
   */
  month: number | null
  /**
   * Used to generate a date label for important track events.
   *
   * For `GeoEvent.TrackingStart` and `GeoEvent.TrackingStop`, this is defined. When present, it is
   * the day of the month, starting with 1 (for the date Dec 1 2024, this value would be `"01"`).
   *
   * For all other event types, it is `null`.
   */
  date: number | null
  /**
   * Used to generate a date label for important track events.
   *
   * For `GeoEvent.TrackingStart` and `GeoEvent.TrackingStop`, this is defined. When present, it is
   * the full year (for the date Dec 1 2024, this value would be `"2024"`).
   *
   * For all other event types, it is `null`.
   */
  year: number | null
}
