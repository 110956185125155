import { ClientError } from '@wildidea/tools/errors/ClientError.js'
import { UnexpectedError } from '@wildidea/tools/errors/ApiError/UnexpectedError.js'
import { ApiError } from '@wildidea/tools/errors/ApiError/ApiError.js'
import { NotFoundError } from '@wildidea/tools/errors/ApiError/NotFoundError.js'

import {
  GetUserMessagesInput,
  GetUserMessagesResponse,
  TrackingProviderId,
  API_PREFIX
} from './model.js'

const OPERATION = 'getUserMessages'

export type GetUserMessagesThrows = ClientError | NotFoundError | UnexpectedError

/** @throws GetUserMessagesThrows */
export const getUserMessages = async (
  input: GetUserMessagesInput
): Promise<GetUserMessagesResponse> => {
  const searchParams = new URLSearchParams(
    Object.entries({
      startDate: input.timeRange?.startDate?.toISOString(),
      endDate: input.timeRange?.endDate?.toISOString(),
      eventIds: input.eventIds?.map((tsid) => new TrackingProviderId(tsid).toString()).join(',')
    }).filter<[string, string]>((entry): entry is [string, string] => !!entry[1])
  )

  return handleGetUserMessagesResponse(
    window.fetch(
      `${API_PREFIX}/users/${new TrackingProviderId(input.user).toString()}/messages${searchParams.size ? `?${searchParams}` : ''}`,
      {
        headers: {
          Accept: 'application/json'
        }
      }
    )
  )
}

const handleGetUserMessagesResponse = async (
  promise: Promise<Response>
): Promise<GetUserMessagesResponse> => {
  let response: Response
  try {
    response = await promise
  } catch (error) {
    throw new ClientError({ operation: OPERATION, cause: error })
  }

  if (response.ok) {
    return (await response.json()) as GetUserMessagesResponse
  }

  throw ApiError.fromJSON({
    operation: OPERATION,
    json: await response.json()
  })
}
